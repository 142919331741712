$primary-theme-color: #fde900;
$secondary-theme-color: #524d51;
$table-line-color: #ccc !default;
$white-color: #fff !default;

// @font-face {
//   font-family: 'CeraProBold';
//   src: url("./assets/fonts/CeraPro-Bold.ttf") format('truetype');
// }
@font-face {
  font-family: 'RobotoMedium';
  src: url("./assets/fonts/Roboto-Medium.ttf") format('truetype');
}
// @font-face {
//   font-family: 'RobotoBold';
//   src: url("./assets/fonts/Roboto-Bold.ttf") format('truetype');
// }

.giftedChatContainer{
  z-index: 66;
}
.chatButton{
  position: fixed;
  right:40px;
  bottom:40px;
  z-index: 66;
  &.btn-just-icon{
    height: 50px;
    width: 50px;
  }
  i{
    font-size:20px;
  }
}
.chatContainer{
  position: fixed;
  right:40px;
  bottom:0px;
  z-index: 66;
  height:400px;
  width:350px;
  border:1px solid $table-line-color ;
  background-color: white;
  // @include respond-below(sm) {
  //   width:100%;
  //   right:0px;
  //   bottom:0px;
  // }
  .btn-just-icon{
    color: white
  }
  .btn.btn-link:hover{
    color: white !important;
  }
  .userList{
    height:84%;
  }
  .userTitle{
    img{
      border-radius: 30px !important;
    }
  }
}



.thumb-user-list .image img{
  border-radius: 30px;
}

.chatBox{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  margin: 40px;
  height:100%;
  margin-bottom: 0;
  font-size: 13px;
}
.chat-content{
  overflow: auto;
  height: 300px;
}

.chatContainer .card-header{
  background: $secondary-theme-color;
  height:56px !important;
}
.header-title{
  height: 50px;
}
.card-header-title i{
  font-size: 10px;
  color: #32e4cd;
  margin-right: 6px;
}
.card-header .card-header-title{
  color: #FFF;
}

// .chat-content p{
//   background: #ecf1f8;
//   padding: 10px;
//   border-radius: 8px;
//   margin-bottom: 0;
// }

// .chat-message-group .chat-thumb{
//   float: left;
//   width: 20%;
// }
// .chat-message-group .chat-messages{
//   float: left;
//   width: 80%;
//   margin-bottom: 20px;
// }
// .chat-message-group .message{
//   float: left;
//   padding: 10px;
//   background: #ecf1f8;
//   font-size: 13px;
//   border-radius: 5px;
//   margin-bottom: 3px;
// }
// .chat-messages .from{
//   float: left;
//   display: block;
//   width: 100%;
//   text-align: left;
//   font-size: 11px;
// }
// .chat-thumb img{
//   border-radius: 40px;
//   height:40px;
// }
// .writer-user .chat-messages{
//   float: right;
//   width: 100%;
// }
// .writer-user .chat-messages .message{
//   float: right;
//   background: $secondary-theme-color;
//   color: #FFF;
// }
// .writer-user .chat-messages .from{
//   float: left;
//   display: block;
//   width: 100%;
//   text-align: right;
// }
// .chat-message-group .typing{
//   float: left;
// }
// .chatBox .card-header-icon i{
//   color: #FFF;
//   font-size: 13px;
// }
/* CSSS */

/* CSS Spinner */
// .spinner {
//   margin: 0 30px;
//   width: 70px;
//   text-align: center;
// }

// .spinner > div {
//   width: 4px;
//   height: 4px;
//   background-color: #888;

//   border-radius: 100%;
//   display: inline-block;
//   -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
//   animation: sk-bouncedelay 1.4s infinite ease-in-out both;
// }

// .spinner .bounce1 {
//   -webkit-animation-delay: -0.32s;
//   animation-delay: -0.32s;
// }

// .spinner .bounce2 {
//   -webkit-animation-delay: -0.16s;
//   animation-delay: -0.16s;
// }

// @-webkit-keyframes sk-bouncedelay {
//   0%, 80%, 100% { -webkit-transform: scale(0) }
//   40% { -webkit-transform: scale(1.0) }
// }

// @keyframes sk-bouncedelay {
//   0%, 80%, 100% { 
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   } 40% { 
//     -webkit-transform: scale(1.0);
//     transform: scale(1.0);
//   }
// }

/* */
// .card-header-title img{
//   border-radius: 40px;
// }



.userList{
  textarea{
    margin-right:10px;
  }
}
.rn-color-1j7ouym {
  color: $secondary-theme-color !important;
}
.rn-backgroundColor-ysccxm{
  background: $secondary-theme-color !important;
}
.rn-paddingTop-wk8lta {
  // margin-top:-5px;
}

.pointer{
  cursor: pointer;
}

.chatHeader{
  background: $secondary-theme-color;
  height: 50px;
  display:flex;
  align-items:center;
  justify-content:center;
  .title{
    color: $white-color !important;
    font-size: 18px;   
    margin:0 !important;
  }
  .backArrow{
    position: absolute;
    color: $white-color;
  }
}
.back{
  position: absolute;
  left:16px;
  color: $white-color;
}

.chatItem{
  border-bottom: 1px solid $table-line-color;
  height: 60px;
  cursor: pointer;
  display:flex;
  align-items:center;
  text-transform: none;
  .userImg{
    height:30px;
    width:30px;
    border-radius:50%;
    padding:16px;
  }
}
.chatRoom{
  width:100vw;
  height:100vh;
  flex:1;
}

.conversationDetail{
  flex: 1;
  height: 450px;
  background: transparent
}
.messageImageContainer{
  .iconContainer{
    img{
      display: none;
      opacity: 0.5; 
    }
  }
  position: relative;
}

.messageImageContainer:hover {
  img{
    display: block !important;
  }
}

.iconContainer{
  width: 30px;
  display: flex;
  align-items: center;
  position: absolute;  
  top: 45%;
}

.iconContainer:hover{
  img{
    display: block !important;
  }
}

.externalRight{
  right: 20px!important;
  left: unset !important;
  justify-content: flex-end;
}
.externalLeft{
  left: 20px!important;
  right: unset !important;
  justify-content: flex-start;
}
.webChatGradient{
  background: rgb(9,9,121);
  background: linear-gradient(166deg, rgba(9,9,121,0) 49%, rgba(2,0,36,0) 53%, rgba(145,145,145,0.40192051820728291) 80%);
}

.slider {
  position: absolute;
  width: 100%;
  top: 75%;
  height: 100%;
  overflow: hidden;
  transition: all 0.5s;
  background: white;
  border-radius: 5px;;
}

.slider.close {
  top: 100%;
  height: 0;
}

.linkModal {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.5s;
  background: rgba(0,0,0,0.35);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .modalContainer{
    width:80%;
    border-radius: 5;
    border:2px solid red;
  }
}

.linkModal.close{
  display: none;
}


.translucentBg{
  height:115%;
  width:100%;
  position: absolute;
  margin-top: -50px;
  background:rgba(0,0,0,0.35) ;
}
.d-none{
  display: none;
}
.w100{
  width:100% !important;
}


.container {
  // width: 100vw;
  // height: 80vh;
  // bottom:50px;
  display: flex;
  // border:2px solid red;
  // position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.header {
  font-weight: 600;
  color: rosybrown;
}

.typingIndicatorContainer {
  display: flex;
  flex: none;
  align-items: flex-end;
  margin: 5 0;
}

.typingIndicatorBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 30px;
  margin: 0px 8px;
  background-color: #f0f1f1;
  border-radius: 26px;
}

.typingIndicatorBubbleDot {
  width: 7px;
  height: 7px;
  margin-right: 2px;
  background-color: #9F9F9F;
  border-radius: 50%;
  animation-name: bounce;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.typingIndicatorBubbleDot:first-of-type {
  margin: 0px 2px;
}

.typingIndicatorBubbleDot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.typingIndicatorBubbleDot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}
.bred{
  border:2px solid red;
}
.typingIndicatorClass{
  margin-top:-35px;
}
.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.firstBubbleContainer{
  margin-top: 40px;

}
.leftFirstBubble{
  margin-left:-20px;
}
.RTLleftFirstBubble{
  margin-right:-20px;
}
.rightFirstBubble{
  margin-right:-17px;
}
.RTLrightFirstBubble{
  margin-left:-15px;
}

.tooltip{
  position: absolute;
  right: 0;
  top: 35%;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #fde900;
  color: #707070;
  text-align: center;
  padding: 10px 20px;
  border-radius: 6px;
  right:0;
  top:30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-family: 'RobotoMedium';
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
div, span{
  font-family: 'RobotoMedium' !important;
  font-weight:500 !important;
}